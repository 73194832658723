import { Interpolation } from "@emotion/core";
import { transparentize } from "polished";
import { Colors } from "../../../design-tokens/colors";
import { DarkTheme, Device } from "../../../design-tokens/media-queries";
import { grid } from "../../../design-tokens/grid";
import BlobGroup from "./assets/blob-group.svg";

const root: Interpolation = {
  position: "relative",
  background: `url(${BlobGroup}) no-repeat`,
  color: Colors.White,
  backgroundColor: "#604189",
  backgroundPosition: "100% 0%",
  backgroundSize: "auto 100%",
  paddingTop: grid(4),
  paddingBottom: grid(5),
  marginBottom: grid(5),

  [DarkTheme]: {
    backgroundColor: transparentize(0.7, Colors.Purple),
  },

  [Device.DesktopTablet]: {
    marginBottom: grid(7),
  },
};

const content: Interpolation = {
  maxWidth: grid(65),
};

const styles = {
  root,
  content,
};

export default styles;
