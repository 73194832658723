import * as React from "react";
import { Link } from "gatsby";
import Section from "../../../ui-library/section/section";
import { getCOVIDIntentUrl } from "../../../../utils/question-url";
import { SPECIAL_INTENTS } from "../../../../constants/special-intents";
import Image from "../../../ui-library/image/image";
import Row from "../../../ui-library/column-system/row";
import Column from "../../../ui-library/column-system/column";
import SectionHeading from "../../../ui-library/section/section-heading";
import NotDoingWellLine from "./assets/not-doing-well-line.svg";
import GettingThereLine from "./assets/getting-there-line.svg";
import DoingWellLine from "./assets/doing-well-line.svg";
import styles from "./countries-performance-ctas.styles";

const CTAS = [
  {
    image: DoingWellLine,
    ctaText: "Doing well",
    tab: "Doing well",
  },
  {
    image: GettingThereLine,
    ctaText: "Getting there",
    tab: "Getting there",
  },
  {
    image: NotDoingWellLine,
    ctaText: "Not doing well",
    tab: "Not doing well",
  },
];

const CountriesPerformanceCTAs: React.FC = () => {
  return (
    <Section>
      <SectionHeading subHeading="See who is taking the most control of their new daily cases and who isn’t doing enough">
        Which countries are doing well?
      </SectionHeading>

      <Row isInline={true}>
        {CTAS.map((cta) => (
          <Column
            key={cta.ctaText}
            spanLg={4}
            spanMd={4}
            spanSm={4}
            isInline={true}
          >
            <Link
              to={`${getCOVIDIntentUrl(
                SPECIAL_INTENTS.SituationSnapshot.alias
              )}?activeCCCSTab=${encodeURIComponent(cta.tab)}`}
              css={styles.root}
            >
              <div css={styles.image}>
                <Image
                  src={cta.image}
                  alt={cta.ctaText}
                  turnOffLazyLoading={true}
                />
              </div>
              <div css={styles.ctaText}>{cta.ctaText}</div>
            </Link>
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default CountriesPerformanceCTAs;
