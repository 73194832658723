import { NavItem } from "../types/nav-item";
import { TOP_NAV_ITEM } from "./top-navigations";

interface SpecialIntent {
  name: string;
  nav: NavItem;
  alias: string;
}

export const SPECIAL_INTENTS: Record<string, SpecialIntent> = {
  GlobalSituation: {
    name: "What is the global COVID-19 situation?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "what-is-the-global-covid-19-situation",
  },
  SituationSnapshotWithTest: {
    name: "Which countries are testing well and doing well?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "which-countries-are-testing-well-and-doing-well",
  },
  SituationSnapshot: {
    name: "Which countries are doing well and not so well?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "which-countries-are-doing-well-and-not-so-well",
  },
  USSituationSnapshot: {
    name: "Which US states are doing well and not so well?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "which-us-states-are-doing-well-and-not-so-well",
  },
  InfectionRate: {
    name: "What are the current infection rates for countries?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "what-are-the-current-infection-rates-for-countries",
  },
  USInfectionRate: {
    name: "What are the current infection rates for US states?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "what-are-the-current-infection-rates-for-us-states",
  },
  CountrySituationList: {
    name: "What countries can I compare and explore?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "what-countries-can-i-compare-and-explore",
  },
  TimeLine: {
    name: "What is the COVID-19 timeline?",
    nav: TOP_NAV_ITEM.Insights,
    alias: "what-is-the-covid-19-timeline",
  },
  HowToWin: {
    name: "How can we win by taking effective action?",
    nav: TOP_NAV_ITEM.Topics,
    alias: "how-can-we-win-by-taking-effective-action",
  },
  WhyMask: {
    name: "Why should most people wear masks?",
    nav: TOP_NAV_ITEM.Topics,
    alias: "why-should-most-people-wear-masks",
  },
  PittaMask: {
    name: "Can Japanese Pitta masks protect against coronavirus?",
    nav: TOP_NAV_ITEM.Topics,
    alias: "can-japanese-pitta-masks-protect-against-coronavirus",
  },
  Resources: {
    name: "What are some good COVID-19 related websites?",
    nav: TOP_NAV_ITEM.Resources,
    alias: "what-are-some-good-covid-19-related-websites",
  },
  Topics: {
    name: "What COVID-19 topics can I explore?",
    nav: TOP_NAV_ITEM.Topics,
    alias: "what-covid-19-topics-can-i-explore",
  },
};
