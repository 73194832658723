import * as React from "react";
import { Link } from "gatsby";
import ContentCard from "../../../../ui-library/content-card/content-card";
import CardContent from "../../../../ui-library/content-card/card-content";
import CardHeading from "../../../../ui-library/content-card/card-heading";
import CardSubHeading from "../../../../ui-library/content-card/card-sub-heading";
import Row from "../../../../ui-library/column-system/row";
import Column from "../../../../ui-library/column-system/column";
import { getCOVIDIntentUrl } from "../../../../../utils/question-url";
import { SPECIAL_INTENTS } from "../../../../../constants/special-intents";
import {
  EventCategory,
  getEventAttributes,
} from "../../../../../utils/analytics";
import styles from "./mask-stop-transmission-infographic.styles";

const embedUrlPrefix =
  "https://askabout.io/media/images/covid-19-infographics/mask-stops-transmission/embed/";
const downloadUrlPrefix =
  "https://askabout.io/media/images/covid-19-infographics/mask-stops-transmission/download/";

const getEmbedImageUrl = (fileName: string, version?: number) =>
  `${embedUrlPrefix}${fileName}?v=${version}`;
const getDownloadImageUrl = (fileName: string, version?: number) =>
  `${downloadUrlPrefix}${encodeURIComponent(fileName)}?v=${version}`;

interface MaskStopTransimissionInfographicProps {
  hasLink?: boolean;
}

const MaskStopTransimissionInfographic: React.FC<MaskStopTransimissionInfographicProps> = ({
  hasLink,
}) => {
  const fileUrls = {
    desktopImgBlue: getDownloadImageUrl(
      "Why wear masks - AskAbout - Desktop - LM - 940px.png",
      4
    ),
    desktopImgDark: getDownloadImageUrl(
      "Why wear masks - AskAbout - Desktop - DM - 940px.png",
      4
    ),
    mobileImgBlue: getDownloadImageUrl(
      "Why wear masks - AskAbout - Mobile - LM - 375px.png",
      4
    ),
    mobileImgDark: getDownloadImageUrl(
      "Why wear masks - AskAbout - Mobile - DM - 375px.png",
      4
    ),
    pdfFile: `https://askabout.io/media/files/covid-19-infographics/${encodeURIComponent(
      "Why wear masks - AskAbout - Printable.pdf"
    )}?v=4`,
  };

  const imageElem = (
    <picture>
      <source
        media="(min-width:650px) and (prefers-color-scheme: dark)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-dm.svg",
          4
        )}
      />
      <source
        media="(min-width:650px)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-lm.svg",
          4
        )}
      />

      <source
        media="(max-width:339px) and (prefers-color-scheme: dark)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-dm-320.svg",
          4
        )}
      />
      <source
        media="(max-width:339px)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-lm-320.svg",
          4
        )}
      />

      <source
        media="(max-width:649px) and (prefers-color-scheme: dark)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-dm-375.svg",
          4
        )}
      />
      <source
        media="(max-width:649px)"
        srcSet={getEmbedImageUrl(
          "mask-stop-transmission-infographic-lm-375.svg",
          4
        )}
      />

      <img
        src={getEmbedImageUrl("mask-stop-transmission-infographic-lm.svg", 3)}
        alt="Mask stops transmission"
        loading="lazy"
        css={{ width: "100%" }}
      />
    </picture>
  );

  const analytics = (label: string) =>
    getEventAttributes({
      category: EventCategory.MaskStopsTransmission,
      action: "Click - Download",
      label,
    });

  return (
    <ContentCard>
      <CardContent>
        <CardHeading>My mask protects you. Your mask protects me.</CardHeading>
        <CardSubHeading>
          One of the most effective actions you can take is to wear a mask
          whenever around people.
        </CardSubHeading>

        {hasLink ? (
          <Link
            css={styles.imageContainer}
            to={getCOVIDIntentUrl(SPECIAL_INTENTS.WhyMask.alias)}
          >
            {imageElem}
          </Link>
        ) : (
          <div css={styles.imageContainer}>{imageElem}</div>
        )}

        <div css={styles.caption}>
          Let’s all wear masks consistently to keep each other safe
        </div>
        <div css={styles.download}>
          <div css={styles.downloadHeading}>Download the infographic</div>
          <Row>
            <Column spanLg={2.4} spanMd={2.4} spanSm={6} spanXs={6}>
              <div>
                <a
                  css={styles.linkStyle}
                  href={fileUrls.desktopImgBlue}
                  download
                  {...analytics("Blue 940px")}
                >
                  Blue 940px
                </a>
              </div>
            </Column>
            <Column spanLg={2.4} spanMd={2.4} spanSm={6} spanXs={6}>
              <div>
                <a
                  css={styles.linkStyle}
                  href={fileUrls.mobileImgBlue}
                  download
                  {...analytics("Blue 375px")}
                >
                  Blue 375px
                </a>
              </div>
            </Column>
            <Column spanLg={2.4} spanMd={2.4} spanSm={6} spanXs={6}>
              <div>
                <a
                  css={styles.linkStyle}
                  href={fileUrls.desktopImgDark}
                  download
                  {...analytics("Dark 940px")}
                >
                  Dark 940px
                </a>
              </div>
            </Column>
            <Column spanLg={2.4} spanMd={2.4} spanSm={6} spanXs={6}>
              <div>
                <a
                  css={styles.linkStyle}
                  href={fileUrls.mobileImgDark}
                  download
                  {...analytics("Dark 375px")}
                >
                  Dark 375px
                </a>
              </div>
            </Column>
            <Column spanLg={2.4} spanMd={2.4} spanSm={12}>
              <div>
                <a
                  css={styles.linkStyle}
                  href={fileUrls.pdfFile}
                  download
                  {...analytics("Printable PDF")}
                >
                  Printable PDF
                </a>
              </div>
            </Column>
          </Row>
        </div>
      </CardContent>
    </ContentCard>
  );
};

export default MaskStopTransimissionInfographic;
