import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FeaturedIntentsQuery } from "../../../../graphql-types";
import Section from "../../ui-library/section/section";
import Row from "../../ui-library/column-system/row";
import Column from "../../ui-library/column-system/column";
import QuestionCard from "../../ui-library/question-card/question-card";
import { EventCategory } from "../../../utils/analytics";
import { getCOVIDIntentUrl } from "../../../utils/question-url";

const FeaturedCovidQuestions: React.FC = () => {
  const data = useStaticQuery<FeaturedIntentsQuery>(
    graphql`
      query FeaturedIntents {
        askAboutQuery {
          covidFeaturedIntents {
            alias
            intentName
            displayName
            displayOrder
          }
        }
      }
    `
  );

  const questions = data.askAboutQuery.covidFeaturedIntents;

  if (!questions) {
    return null;
  }

  return (
    <Section heading="Frequently asked COVID-19 questions">
      <Row>
        {questions.map((q) => (
          <Column key={q.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionCard
              name={q.displayName}
              url={getCOVIDIntentUrl(q.alias)}
              eventCategory={EventCategory.Section_MostAsked}
            />
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default FeaturedCovidQuestions;
