import { Interpolation } from "@emotion/core";
import { Device, DarkTheme } from "../../../../design-tokens/media-queries";
import { grid } from "../../../../design-tokens/grid";
import { Fonts } from "../../../../design-tokens/fonts";
import { Colors } from "../../../../design-tokens/colors";
import { Border } from "../../../../design-tokens/borders";

const imageContainer: Interpolation = {
  display: "block",
  marginBottom: grid(4),

  [Device.Mobile]: {
    marginLeft: grid(-2),
    marginRight: grid(-2),
    marginBottom: grid(3),
  },
};

const caption: Interpolation = {
  fontSize: 18,
  fontWeight: Fonts.Weight.Bold,
  textAlign: "center",
  marginBottom: grid(3),

  [Device.DesktopTablet]: {
    fontSize: 24,
    marginBottom: grid(4),
  },
};

const download: Interpolation = {
  backgroundColor: Colors.LightGrey,
  padding: `${grid(2)} ${grid(2)} ${grid(1)}`,
  borderRadius: Border.Radius,

  [Device.Desktop]: {
    padding: `${grid(2.5)} ${grid(3)} ${grid(1)}`,
  },

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },
};

const downloadHeading: Interpolation = {
  textAlign: "center",
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    fontSize: 18,
  },
};

const linkStyle: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  minHeight: grid(5),
  lineHeight: 1.25,
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: Colors.Blue,
  padding: `${grid(1)} ${grid(1)}`,
  borderRadius: Border.RadiusSm,
  transition: "all 0.2s ease",

  ":hover": {
    transform: "translateY(-2px)",
    textDecoration: "none",
  },
};

const styles = {
  imageContainer,
  caption,
  download,
  downloadHeading,
  linkStyle,
};

export default styles;
