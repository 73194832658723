import * as React from "react";
import SectionHeading from "../../../ui-library/section/section-heading";
import Container from "../../../ui-library/container/container";
import styles from "./countries-gov-response-banner.styles";
import CountryGovResAutoSuggest from "./country-gov-res-auto-suggest/country-gov-res-auto-suggest";

const CountriesGovResponseBanner: React.FC = () => {
  return (
    <div css={styles.root}>
      <Container>
        <SectionHeading subHeading="What measures have different governments around the world implemented to battle the virus?">
          How have governments responded to the crisis?
        </SectionHeading>

        <div css={styles.content}>
          <CountryGovResAutoSuggest />
        </div>
      </Container>
    </div>
  );
};

export default CountriesGovResponseBanner;
