import * as React from "react";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Fonts } from "../../design-tokens/fonts";
import { Colors } from "../../design-tokens/colors";

interface CardHeadingProps {
  headingDetail?: string;
}

const CardHeading: React.FC<CardHeadingProps> = ({
  headingDetail,
  children,
}) => {
  return (
    <div
      css={{
        marginBottom: grid(2),

        [Device.DesktopTablet]: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: grid(3),
        },
      }}
    >
      <h2
        css={{
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: Fonts.Weight.Bold,

          [Device.DesktopTablet]: {
            fontSize: "30px",
            lineHeight: "36px",
          },

          [Device.Mobile]: {
            marginBottom: headingDetail ? grid(1) : 0,
          },
        }}
      >
        {children}
      </h2>
      {!!headingDetail && (
        <div
          css={{
            fontSize: "12px",
            color: Colors.MidGrey,

            [DarkTheme]: {
              color: Colors.DarkModeTextGrey,
            },
          }}
        >
          {headingDetail}
        </div>
      )}
    </div>
  );
};

export default CardHeading;
