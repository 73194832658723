import { Interpolation } from "@emotion/core";
import { transparentize } from "polished";
import { Colors } from "../../design-tokens/colors";
import { grid } from "../../design-tokens/grid";
import { Keyframes } from "../../design-tokens/animation";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Border } from "../../design-tokens/borders";
import { Fonts } from "../../design-tokens/fonts";
import { BoxShadow } from "../../design-tokens/box-shadow";
import MobileViruses from "./assets/three-viruses-mobile.svg";
import ThreeViruses from "./assets/three-viruses.svg";

const card: Interpolation = {
  position: "relative",
  display: "block",
  color: Colors.White,
  backgroundColor: Colors.DarkBlue,
  marginBottom: grid(4),
  borderRadius: Border.RadiusLg,
  boxShadow: BoxShadow.Default,
  animation: `${Keyframes.FadeIn} 0.3s linear`,

  ":hover": {
    textDecoration: "none",
  },

  [Device.DesktopTablet]: {
    marginBottom: grid(6),
  },
};

const virusImage: Interpolation = {
  position: "absolute",
  bottom: -64,
  right: 10,

  [Device.Mobile]: {
    display: "none",
  },

  [DarkTheme]: {
    opacity: 0.95,
  },
};

const background: Interpolation = {
  background: `url(${MobileViruses}) no-repeat`,
  backgroundPosition: "right bottom",
  padding: `${grid(2.5)} ${grid(2)} ${grid(10)}`,

  [Device.DesktopTablet]: {
    background: `url(${ThreeViruses}) no-repeat`,
    backgroundPosition: "right bottom",
    backgroundSize: "auto 100%",
    padding: `${grid(3)} ${grid(5)} ${grid(3.5)}`,
  },

  [Device.Tablet]: {
    background: "none",
  },
};

const heading: Interpolation = {
  display: "flex",
  alignItems: "center",
  maxWidth: grid(26),
  fontSize: "28px",
  lineHeight: "30px",
  fontWeight: Fonts.Weight.Bold,
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    maxWidth: grid(50),
    fontSize: "35px",
    lineHeight: "40px",
  },
};

const headingBadge: Interpolation = {
  display: "inline-block",
  fontSize: "10px",
  lineHeight: "19px",
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: transparentize(0.2, Colors.Pink),
  borderRadius: grid(2),
  padding: `0 ${grid(1)}`,
  marginLeft: grid(1.5),
};

const subHeading: Interpolation = {
  maxWidth: grid(26),
  fontSize: "18px",
  marginBottom: grid(2.5),

  [Device.DesktopTablet]: {
    maxWidth: grid(50),
    fontSize: "20px",
    marginBottom: grid(3),
  },
};

const cta: Interpolation = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  height: grid(5),
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: Colors.Blue,
  whiteSpace: "nowrap",
  paddingLeft: grid(2),
  paddingRight: grid(2),
  borderRadius: Border.RadiusSm,

  [Device.DesktopTablet]: {
    minWidth: grid(24),
  },

  [Device.Mobile]: {
    width: "100%",
  },
};

const styles = {
  card,
  virusImage,
  background,
  heading,
  headingBadge,
  subHeading,
  cta,
};

export default styles;
