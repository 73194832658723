import { Interpolation } from "@emotion/core";
import { grid } from "../../../../design-tokens/grid";
import { Device, DarkTheme } from "../../../../design-tokens/media-queries";
import { Border } from "../../../../design-tokens/borders";
import { Colors } from "../../../../design-tokens/colors";
import { Fonts } from "../../../../design-tokens/fonts";

const inputContainer: Interpolation = {
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: grid(6),
  fontFamily: "inherit",
  fontSize: Fonts.Size.Default,
  backgroundColor: Colors.White,
  paddingLeft: grid(1.5),
  borderRadius: Border.Radius,
  transition: "all 0.2s ease",

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },

  [Device.Mobile]: {
    height: grid(5),
    paddingLeft: grid(1),
  },
};

const inputFocus: Interpolation = {
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  border: `1px solid ${Colors.BorderGrey}`,
  borderBottom: 0,

  [DarkTheme]: {
    border: `1px solid ${Colors.DarkModeBorder}`,
    borderBottom: 0,
  },
};

const input: Interpolation = {
  flex: 1,
  height: "100%",
  fontSize: "18px",
  border: 0,
  outline: 0,
  backgroundColor: "transparent",
  WebkitAppearance: "none",

  "::placeholder": {
    [DarkTheme]: {
      color: Colors.DarkModeTextGrey,
    },
  },

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },

  "&:focus": {
    outline: 0,
  },

  [Device.MobilePortXs]: {
    fontSize: "16px",
  },
};

const askCTA: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 65,
  height: 40,
  fontSize: 14,
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: Colors.Blue,
  borderRadius: 6,
  marginRight: 10,
  cursor: "pointer",

  [Device.Mobile]: {
    width: 45,
    height: 30,
  },

  [Device.DesktopTablet]: {
    paddingLeft: 10,
  },
};

const cancelInputButton: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 30,
  height: 60,
  cursor: "pointer",
  marginLeft: grid(1),
  marginRight: grid(1),
  overflow: "hidden",
  transition: "all 0.1s linear",

  [Device.Desktop]: {
    display: "none",
  },
};

const cleanInputButtonHidden: Interpolation = {
  width: "0px !important",
  marginLeft: "0px !important",
};

const hidden: Interpolation = {
  display: "none",
};

const hiddenMobile: Interpolation = {
  [Device.Mobile]: {
    display: "none",
  },
};

const styles = {
  inputContainer,
  inputFocus,
  input,
  askCTA,
  cancelInputButton,
  cleanInputButtonHidden,
  hidden,
  hiddenMobile,
};

export default styles;
