import * as React from "react";
import { Device } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";

const CardSubHeading: React.FC = ({ children }) => {
  return (
    <div
      css={{
        marginBottom: grid(3),

        [Device.DesktopTablet]: {
          marginBottom: grid(4),
        },
      }}
    >
      {children}
    </div>
  );
};

export default CardSubHeading;
