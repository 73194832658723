import { Interpolation } from "@emotion/core";
import { Border } from "../../../design-tokens/borders";
import { Colors } from "../../../design-tokens/colors";
import { DarkTheme } from "../../../design-tokens/media-queries";
import { grid } from "../../../design-tokens/grid";
import { Fonts } from "../../../design-tokens/fonts";
import { BoxShadow } from "../../../design-tokens/box-shadow";

const root: Interpolation = {
  position: "relative",
  display: "block",
  color: Colors.Black,
  backgroundColor: Colors.White,
  padding: `${grid(3)} ${grid(2)}`,
  boxShadow: BoxShadow.Default,
  borderRadius: Border.Radius,
  transition: "all 0.2s ease",
  overflow: "hidden",

  [DarkTheme]: {
    color: Colors.White,
    backgroundColor: Colors.DarkModeBackground,
  },

  ":hover": {
    textDecoration: "none",
    transform: "translateY(-4px)",
  },
};

const image: Interpolation = {
  paddingLeft: grid(1),
  paddingRight: grid(1),
  marginBottom: grid(4),

  "> img": {
    width: "100%",
  },
};

const ctaText: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: grid(4),
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Blue,
  backgroundColor: Colors.LightGrey,
  borderRadius: grid(4),
  marginTop: "auto",
  marginBottom: 0,

  ":hover": {
    textDecoration: "none",
  },

  [DarkTheme]: {
    color: Colors.DarkModeTextBlue,
    backgroundColor: Colors.DarkModeBlack,
  },
};

const styles = {
  root,
  image,
  ctaText,
};

export default styles;
