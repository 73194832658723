import * as React from "react";
import { Link } from "gatsby";
import Section from "../../ui-library/section/section";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";
import Image from "../../ui-library/image/image";
import styles from "./banner.styles";
import BigVirus from "./assets/big-virus.svg";

interface BannerProps {
  heading: string;
  description: string;
  ctaText: string;
  url: string;
  badge?: string;
}

const Banner: React.FC<BannerProps> = ({
  heading,
  badge,
  description,
  url,
  ctaText,
}) => {
  return (
    <Section>
      <Link
        to={url}
        css={styles.card}
        {...getEventAttributes({
          category: EventCategory.HomeBanner,
          action: "Click - Home banner",
          label: "Banner",
        })}
      >
        <div css={styles.background}>
          <h2 css={styles.heading}>
            {heading}
            {badge && <span css={styles.headingBadge}>{badge}</span>}
          </h2>
          <div css={styles.subHeading}>{description}</div>
          <div css={styles.cta}>{ctaText}</div>
        </div>
        <Image
          src={BigVirus}
          alt="Coronavirus"
          css={styles.virusImage}
          turnOffLazyLoading={true}
        />
      </Link>
    </Section>
  );
};

export default Banner;
