import { Interpolation } from "@emotion/core";
import { grid } from "../../../../design-tokens/grid";
import { Colors } from "../../../../design-tokens/colors";
import { DarkTheme, Device } from "../../../../design-tokens/media-queries";
import { Border } from "../../../../design-tokens/borders";

const root: Interpolation = {
  position: "absolute",
  top: 49,
  left: -1,
  right: -1,
  maxHeight: grid(30),
  backgroundColor: Colors.White,
  borderBottomLeftRadius: Border.Radius,
  borderBottomRightRadius: Border.Radius,
  border: `1px solid ${Colors.BorderGrey}`,
  overflowY: "scroll",
  transition: "max-height 0.2s ease",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
  zIndex: 10,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
    border: `1px solid ${Colors.DarkModeBorder}`,
  },

  [Device.DesktopTablet]: {
    top: 59,
  },

  [Device.Desktop]: {
    "::-webkit-scrollbar": {
      width: 10,
    },

    "::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "::-webkit-scrollbar-thumb": {
      backgroundColor: Colors.BorderGrey,
      borderRadius: "10px",

      [DarkTheme]: {
        backgroundColor: Colors.DarkModeBorder,
      },
    },
  },
};

const suggestItem: Interpolation = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: grid(5),
  borderBottom: `1px solid ${Colors.BorderGrey}`,
  paddingLeft: grid(1.5),
  paddingRight: grid(1.5),
  whiteSpace: "nowrap",
  textOverflow: "elipsis",
  overflow: "hidden",
  cursor: "pointer",

  ":last-of-type": {
    borderBottom: 0,
  },

  ":hover": {
    backgroundColor: Colors.LightGrey,
    textDecoration: "none",
  },

  [DarkTheme]: {
    borderBottom: `1px solid ${Colors.DarkModeBorder}`,

    ":hover": {
      backgroundColor: Colors.DarkModeBackground,
    },
  },

  [Device.DesktopTablet]: {
    fontStyle: "18px",
    paddingLeft: grid(2),
    paddingRight: grid(2),
  },
};

const caseNumber: Interpolation = {
  color: Colors.MidGrey,

  [DarkTheme]: {
    color: Colors.MidGrey,
  },

  [Device.Mobile]: {
    display: "none",
  },
};

const styles = {
  root,
  suggestItem,
  caseNumber,
};

export default styles;
