import * as React from "react";
import Container from "../../ui-library/container/container";
import Banner from "../banner/banner";
import FeaturedCovidQuestions from "../featured-covid-questions/featured-covid-questions";
import RecentlyAskedQuestions from "../recently-asked-questions/recently-asked-questions";
import { getCOVIDIntentUrl } from "../../../utils/question-url";
import { SPECIAL_INTENTS } from "../../../constants/special-intents";
import ECVBanner from "../third-party-banner/ecv-banner";
import CollectQAndA from "../../common-page-sections/collect-q-and-a/collect-q-and-a";
import PageContainer from "../../ui-library/page-container/page-container";
import MaskStopTransimissionInfographic from "../covid-intent/intent-specific-answers/mask-stop-transmission-infographic/mask-stop-transmission-infographic";
import CountriesPerformanceCTAs from "./countries-performance-ctas/countries-performance-ctas";
import CountriesSituationCTAs from "./countries-situation-ctas/countries-situation-ctas";
import CountriesGovResponseBanner from "./countries-gov-response-banner/countries-gov-response-banner";

const COVID19Home: React.FC = () => {
  return (
    <PageContainer>
      <Container>
        <Banner
          heading="How can we win?"
          description="Essential guidelines to eliminate COVID-19"
          url={getCOVIDIntentUrl(SPECIAL_INTENTS.HowToWin.alias)}
          ctaText="Let’s Crush the curve together"
        />

        <FeaturedCovidQuestions />

        <CountriesPerformanceCTAs />

        <CountriesSituationCTAs />
      </Container>

      <CountriesGovResponseBanner />

      <Container fullWidthOnMobile={true}>
        <MaskStopTransimissionInfographic hasLink={true} />
      </Container>

      <Container>
        <RecentlyAskedQuestions />

        <ECVBanner />

        <CollectQAndA />
      </Container>
    </PageContainer>
  );
};

export default COVID19Home;
