import React, { useContext } from "react";
import { AskAboutContext } from "../../../../../site-context/ask-about-context";
import { useClickOutHooks } from "../../../../../hooks/use-click-out-hooks";
import Image from "../../../../ui-library/image/image";
import clearInputIcon from "./assets/clear-icon.svg";
import askCTAIcon from "./assets/ask-cta.svg";
import styles from "./country-gov-res-auto-suggest.styles";
import CountryGovWithTimelineSuggestions from "./country-gov-with-timeline-suggestions";

const CountryGovResAutoSuggest: React.FC = () => {
  const {
    store: { isMobile },
  } = useContext(AskAboutContext);

  const inputRef = React.useRef<HTMLDivElement>(null);

  const [termState, setTermState] = React.useState<string>("");
  const [isFocused, setFocused] = React.useState<boolean>(false);

  useClickOutHooks({
    containerRef: inputRef,
    callback: () => setFocused(false),
    isFocused,
  });

  const closeAsk = () => {
    setFocused(false);
  };

  return (
    <div
      ref={inputRef}
      css={[styles.inputContainer, isFocused && styles.inputFocus]}
    >
      <input
        type="search"
        css={styles.input}
        value={termState}
        placeholder={
          isMobile
            ? "Enter a country..."
            : "Which country do you want to look into?"
        }
        onFocus={() => {
          setFocused(true);
        }}
        onChange={(e) => setTermState(e.target.value)}
      />
      <div
        onClick={() => setFocused(true)}
        css={[styles.askCTA, isFocused && styles.hiddenMobile]}
      >
        Ask
        {!isMobile && (
          <Image src={askCTAIcon} alt="Ask" turnOffLazyLoading={true} />
        )}
      </div>
      {isFocused && (
        <div
          onClick={closeAsk}
          css={[
            styles.cancelInputButton,
            !isFocused &&
              termState.length === 0 &&
              styles.cleanInputButtonHidden,
          ]}
        >
          <Image src={clearInputIcon} alt="Clear" turnOffLazyLoading={true} />
        </div>
      )}
      {isFocused && (
        <CountryGovWithTimelineSuggestions
          onClick={() => setFocused(false)}
          countryTerm={termState}
          isFocused={isFocused}
        />
      )}
    </div>
  );
};

export default CountryGovResAutoSuggest;
