import * as React from "react";
import Fuse from "fuse.js";
import { Link } from "gatsby";
import { useCountriesWithResponseQuery } from "../../../../../hooks/static-queries/use-countries-with-response-query";
import { getCountryWithResUrl } from "../../../../../utils/question-url";
import {
  getEventAttributes,
  EventCategory,
} from "../../../../../utils/analytics";
import styles from "./country-gov-with-timeline-suggestions.styles";

interface CountryGovWithTimelineSuggestionsProps {
  isFocused: boolean;
  countryTerm: string;
  onClick: () => void;
}

const options = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.3,
  keys: ["displayName", "name"],
};

const CountryGovWithTimelineSuggestions: React.FC<CountryGovWithTimelineSuggestionsProps> = ({
  isFocused,
  countryTerm,
  onClick,
}) => {
  const countries = useCountriesWithResponseQuery();
  const fuse = new Fuse(countries, options);
  const matchingCountries = fuse.search(countryTerm).map((c) => c.item);
  const countriesToDisplay =
    matchingCountries.length > 0 ? matchingCountries : countries;

  return (
    <div
      css={[
        styles.root,
        !isFocused && {
          maxHeight: 0,
          border: "0 !important",
          boxShadow: "none",
        },
      ]}
    >
      {countriesToDisplay.map((c) => (
        <Link
          key={c.name}
          to={getCountryWithResUrl(c.alias)}
          css={styles.suggestItem}
          onClick={onClick}
          {...getEventAttributes({
            category: EventCategory.COVID_HomePage,
            action: "Click - Country government response link",
            label: c.displayName,
          })}
        >
          <span>{c.displayName} government response</span>
        </Link>
      ))}
    </div>
  );
};

export default CountryGovWithTimelineSuggestions;
